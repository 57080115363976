import "./loader.scss";

const Loader = () => {
    return <>
        <div className="spinner-container">
            <div className="sloy">
                <span className="loader"></span>
            </div>

        </div>
    </>
};

export default Loader;